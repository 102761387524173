import gql from "graphql-tag";
import { PrivateEventCoreInfoFragment, PrivateEventRemoteCalendarInfoFragment } from "./event";
import { PrivateClientDefaultContactInfoFragment } from "./client";
import { PrivateEventReservationsCoreInfoFragment } from "./event_reservations";
import { PrivateSchedulerGeocodedAvailabilityFragment } from "./scheduler_v2";

// ### QUERIES ###########################################################################################

export const PrivateSchedulerV2SearchProgressQuery = gql`
  query ($technicianSearchSignatures: [String!]!) {
    schedulerV2SearchProgress(technicianSearchSignatures: $technicianSearchSignatures)
  }
`;

export const PrivateSchedulerV2SearchResultsQuery = gql`
  query ($technicianSearchSignatures: [String!]!) {
    schedulerV2SearchResults(technicianSearchSignatures: $technicianSearchSignatures) {
      errors
      results {
        technicianSearchSignature
        slots {
          startsAt timezone duration buffer technicianId weight
          afterTrafficMinutes afterTravelMinutes
          beforeTrafficMinutes beforeTravelMinutes
          outsideServiceAreaMinutes outsideServiceAreaMiles
          filteredReasons flags
          latitude longitude travelMode availabilityId
        }
      }
    }
  }
`;

export const getPrivateSlotMapDataBatchedQueryV2 = (variables: {date: string, userId: string}[]) => {
  let queryStrings: string[] = [];
  let newVariables: any = {};
  let params: string[] = [];
  variables.forEach((vars, i) => {
    queryStrings.push(`
      allEvents${i}: allEvents (userId: $userId${i}, startOn: $date${i}, endOn: $date${i}) {
        ...PrivateEventCoreInfoFragment
        ...PrivateEventRemoteCalendarInfoFragment
        client {
          ...PrivateClientDefaultContactInfoFragment
        }
      }
      allEventReservations${i}: allEventReservations (userId: $userId${i}, startOn: $date${i}, endOn: $date${i}, status: PENDING) {
        ...PrivateEventReservationsCoreInfoFragment
      }
      allAvailability${i}: allSchedulerAvailabilities (userId: $singleUserId${i}, startOn: $date${i}, endOn: $date${i}) {
        ...PrivateSchedulerGeocodedAvailabilityFragment
      }
    `);
    newVariables[`date${i}`] = vars.date;
    newVariables[`userId${i}`] = vars.userId;
    newVariables[`singleUserId${i}`] = vars.userId;
    params.push(`$date${i}: CoreDate!`);
    params.push(`$userId${i}: [String!]!`);
    params.push(`$singleUserId${i}: String`);
  });

  return {
    query: gql`
      query (${params.join(", ")}) { ${queryStrings.join("\n")} }
      ${PrivateSchedulerGeocodedAvailabilityFragment}
      ${PrivateEventRemoteCalendarInfoFragment}
      ${PrivateEventCoreInfoFragment}
      ${PrivateClientDefaultContactInfoFragment}
      ${PrivateEventReservationsCoreInfoFragment}
    `,
    variables: newVariables
  };
};


// ### MUTATIONS ###########################################################################################

export const PrivateDeleteSchedulerAvailabilityMutation = gql`
  mutation ($id: String!) {
    deleteSchedulerAvailability(id: $id) {
      mutationErrors { fieldName key messages errorReferenceId type }
      success
    }
  }
`;

export const PrivateDeleteSchedulerServiceAreaMutation = gql`
  mutation ($id: String!) {
    deleteSchedulerServiceArea(id: $id) {
      mutationErrors { fieldName key messages errorReferenceId type }
      success
    }
  }
`;


export const PrivateSchedulerV2SearchMutation = gql`
  mutation ($searchParams: PrivateSchedulerV2SearchInput!) {
    schedulerV2Search(searchParams: $searchParams) {
      mutationErrors { key fieldName messages }
      technicianSearchSignatures
      warnings searchId
    }
  }
`;
