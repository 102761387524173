import { PIANO_TYPE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_pianoTypeAutoharp,
  MSG_pianoTypeClavichord,
  MSG_pianoTypeConsole,
  MSG_pianoTypeDigital,
  MSG_pianoTypeFortepiano,
  MSG_pianoTypeGrand,
  MSG_pianoTypeHarpsichord,
  MSG_pianoTypeHybrid,
  MSG_pianoTypeOrgan,
  MSG_pianoTypeSpinet,
  MSG_pianoTypeSquareGrand,
  MSG_pianoTypeUpright,
  MSG_pianoTypeStudio,
  MSG_unknownTitle
} from "../strings";


// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedPianoTypes = [
  PIANO_TYPE.UNKNOWN,
  PIANO_TYPE.GRAND,
  PIANO_TYPE.UPRIGHT,
  PIANO_TYPE.CONSOLE,
  PIANO_TYPE.SPINET,
  PIANO_TYPE.STUDIO,
  PIANO_TYPE.AUTOHARP,
  PIANO_TYPE.CLAVICHORD,
  PIANO_TYPE.DIGITAL,
  PIANO_TYPE.FORTEPIANO,
  PIANO_TYPE.HARPSICHORD,
  PIANO_TYPE.HYBRID,
  PIANO_TYPE.ORGAN,
  PIANO_TYPE.SQUARE_GRAND,
];

const PianoTypeInfo: {[key: string]: {type: PIANO_TYPE, getLabel: (locale?: string) => string}} = {
  [PIANO_TYPE.GRAND]: {
    type: PIANO_TYPE.GRAND,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeGrand, {}, locale),
  },
  [PIANO_TYPE.UPRIGHT]: {
    type: PIANO_TYPE.UPRIGHT,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeUpright, {}, locale),
  },
  [PIANO_TYPE.CONSOLE]: {
    type: PIANO_TYPE.CONSOLE,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeConsole, {}, locale),
  },
  [PIANO_TYPE.SPINET]: {
    type: PIANO_TYPE.SPINET,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeSpinet, {}, locale),
  },
  [PIANO_TYPE.STUDIO]: {
    type: PIANO_TYPE.STUDIO,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeStudio, {}, locale),
  },
  [PIANO_TYPE.AUTOHARP]: {
    type: PIANO_TYPE.AUTOHARP,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeAutoharp, {}, locale),
  },
  [PIANO_TYPE.CLAVICHORD]: {
    type: PIANO_TYPE.CLAVICHORD,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeClavichord, {}, locale),
  },
  [PIANO_TYPE.DIGITAL]: {
    type: PIANO_TYPE.DIGITAL,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeDigital, {}, locale),
  },
  [PIANO_TYPE.FORTEPIANO]: {
    type: PIANO_TYPE.FORTEPIANO,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeFortepiano, {}, locale),
  },
  [PIANO_TYPE.HARPSICHORD]: {
    type: PIANO_TYPE.HARPSICHORD,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeHarpsichord, {}, locale),
  },
  [PIANO_TYPE.HYBRID]: {
    type: PIANO_TYPE.HYBRID,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeHybrid, {}, locale),
  },
  [PIANO_TYPE.ORGAN]: {
    type: PIANO_TYPE.ORGAN,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeOrgan, {}, locale),
  },
  [PIANO_TYPE.SQUARE_GRAND]: {
    type: PIANO_TYPE.SQUARE_GRAND,
    getLabel: (locale?: string) => formatMessage(MSG_pianoTypeSquareGrand, {}, locale),
  },
  [PIANO_TYPE.UNKNOWN]: {
    type: PIANO_TYPE.UNKNOWN,
    getLabel: (locale?: string) => formatMessage(MSG_unknownTitle, {}, locale),
  },
};

export { SortedPianoTypes, PianoTypeInfo };
