import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Input, Label } from 'reactstrap';
import { MasterServiceGroup } from '@core';
import { PianoWrapper } from '@client_scheduler/models/piano_wrapper';

import { MasterServiceItemRow } from './MasterServiceItemRow';
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_noneLabel } from "@gazelle/shared/strings";

import { rootStoreInstance } from "@client_scheduler/modules/root";

interface IMasterServiceGroupRowProps {
  group: MasterServiceGroup,
  pianoWrapper: PianoWrapper
}

let globalGroupKey = 0;

const MasterServiceGroupRow = observer(
  class MasterServiceGroupRow extends React.Component<IMasterServiceGroupRowProps> {
    groupKey: number;

    constructor(props: IMasterServiceGroupRowProps) {
      super(props);
      this.groupKey = globalGroupKey++;
    }

    handleChange = (e: any) => {
      // If they're resetting the services and had previously selected users, we should also reset
      // the selected technicians because different services may require different technicians.
      if (rootStoreInstance.prefStore.selectedUsers.length > 0) {
        rootStoreInstance.prefStore.resetSelectedUsers();
      }
      if (e.target.checked) {
        this.props.pianoWrapper.services.delete(this.props.group.id);
      }
    };

    render(): React.ReactElement {
      let { group } = this.props;
      return (
        <div className="mt-2 mb-2">
          <div className="fw-bold">{group.name.defaultValue}</div>
          <div className="ps-4">
            {!group.isMultiChoice &&
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    checked={!this.props.pianoWrapper.services.get(group.id)}
                    onChange={this.handleChange}
                    name={`radio-group-${this.groupKey}-${group.id}`} />
                  {' '}
                  {formatMessage(MSG_noneLabel)}
                </Label>
              </FormGroup>}
            {group.allMasterServiceItems.map((item) => {
              return (<MasterServiceItemRow
                        pianoWrapper={this.props.pianoWrapper}
                        key={item.id}
                        item={item}
                        groupKey={this.groupKey}
                        group={group}/>);
            })}
          </div>
        </div>
      );
    }
  }
);

export { MasterServiceGroupRow };
