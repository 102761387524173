import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Input, Label } from 'reactstrap';
import { MasterServiceGroup, MasterServiceItem } from '@core';
import { PianoWrapper } from '@client_scheduler/models/piano_wrapper';
import { ContextHelpText } from "@gazelle/shared/components/core/ContextHelpText";
import { rootStoreInstance } from "@client_scheduler/modules/root";
import { GazelleDuration } from "@gazelle/shared/components/GazelleDateTime";
import { GazelleCurrency } from "@gazelle/shared/components/GazelleNumber";

interface IMasterServiceItemRowProps {
  item: MasterServiceItem,
  group: MasterServiceGroup,
  groupKey: number,
  pianoWrapper: PianoWrapper
}

const MasterServiceItemRow = observer(
  class MasterServiceItemRow extends React.Component<IMasterServiceItemRowProps> {
    handleChange = (e: any) => {
      const { item } = this.props;
      if (this.props.group?.isMultiChoice) {
        if (e.target.checked) {
          this.props.pianoWrapper.set(item);
        } else {
          this.props.pianoWrapper.unset(item);
        }
      } else {
        const { group } = this.props;
        if (e.target.checked) {
          this.props.pianoWrapper.services.set(group.id, item.id);
        } else {
          this.props.pianoWrapper.services.delete(group.id);
        }
      }

      // If they're resetting the services and had previously selected users, we should also reset
      // the selected technicians because different services may require different technicians.
      if (rootStoreInstance.prefStore.selectedUsers.length > 0) {
        rootStoreInstance.prefStore.resetSelectedUsers();
      }
    };

    render() {
      const { item, group, groupKey } = this.props;
      const showCosts = rootStoreInstance.companySettings.showCosts;

      return (
        <FormGroup check className="master-service-item-row">
          <Label check>
            {(group && !group.isMultiChoice)
              ? <Input
                  type="radio"
                  checked={this.props.pianoWrapper.services.get(group.id) === item.id}
                  onChange={this.handleChange}
                  name={`radio-group-${groupKey}-${group.id}`} />
              : <Input
                  type="checkbox"
                  checked={!!this.props.pianoWrapper.services.get(item.id)}
                  onChange={this.handleChange} />}
            {' '}{item.name.defaultValue}

            <div className="text-small text-muted">
              <GazelleDuration minutes={item.duration} />
              {showCosts &&
                <span> &mdash; <GazelleCurrency value={item.type === 'LABOR_HOURLY' ? (item.duration / 60) * item.amount : item.amount}/></span>}
              {item.description.defaultValue &&
                <span> &mdash; {item.description.defaultValue}</span>}
            </div>
            {!!item.educationDescription.defaultValue && (
              <ContextHelpText narrow noPadding>{item.educationDescription.defaultValue}</ContextHelpText>
            )}
          </Label>
        </FormGroup>
      );
    }
  }
);

export { MasterServiceItemRow };
