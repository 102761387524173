import * as moment from 'moment-timezone';
import { action, computed, configure, makeObservable, observable, ObservableMap } from 'mobx';
import { PianoStore } from '@client_scheduler/modules/step2_pianos';
import { PrefStore } from '@client_scheduler/modules/step3_prefs';
import { SearchStore } from '@client_scheduler/modules/step4_search';
import { ContactStore } from '@client_scheduler/modules/step5_contact';
import {
  actionLogger,
  COMPANY_LOCALIZATION_FIELDS,
  ESTIMATE_ALL_GQL_QUERY_FIELDS,
  Geocode,
  getUrlPart,
  graphqlRequest,
  I18nString,
  LoadingIndicator,
  MasterServiceGroup,
  MasterServiceItem,
  Piano,
  urlContainsDebug,
  User
} from '@core';
import { AzureMappingTypeahead, GoogleMappingTypeahead, IMappingTypeahead, } from "@gazelle/shared/mapping";
import { LOCATION_TYPE, LOCATION_TYPE_NAME, MAPPING_LOCATION_TYPE, MAPPING_PROVIDER_TYPE } from "@gazelle/shared/enums";
import {
  getLocationTypeName,
  LocationAddressPartsType,
  LocationCoordinatesType,
  locationToSingleLine,
  registerLocaleStoreInstance,
  setAddressDisplayTemplates
} from "@gazelle/shared/utils";
import { initGraphql } from "@core/graphql/init";
import { getBrowserLanguageCode } from "@gazelle/shared/browser";
import { Estimate, EstimateTier, Localization } from "@gazelle/shared/models";
import { CurrentLocalizationStore } from "@gazelle/shared/stores/current_localization_store";
import { registerGlobalCurrency } from "@gazelle/shared/stores/global_currency_store";
import { PianoWrapper } from "@client_scheduler/models/piano_wrapper";
import {
  getGoogleInteractiveItineraryMapContainer,
  getGoogleInteractiveMapContainer,
  getGoogleInteractiveMapContainerByAddress,
  GoogleMappingInteractiveMap,
  IMappingInteractiveMap,
} from "@gazelle/shared-web/mapping";
import { flatten } from 'lodash';
import { translationStrings } from "@core/utils/intl";
import { ICoordinates } from "@gazelle/shared/interfaces";
import { LocationStore } from '../step1_address/location_store';

// Make MobX less strict.
configure({enforceActions: "never"});

const clientId = getUrlPart(2);
const estimateId = getUrlPart(3);
const estimateTierId = getUrlPart(4);

interface IStoreOptions {
  useTestData?: boolean,
}

class RootStore {
  clientId: string = null;
  locationStore: LocationStore = null;
  pianoStore: PianoStore = null;
  prefStore: PrefStore = null;
  searchStore: SearchStore = null;
  contactStore: ContactStore = null;

  gak: string = null;
  azk: string = null;
  mbpk: string = null;

  typeaheadProvider: IMappingTypeahead = null;
  interactiveMapProvider: IMappingInteractiveMap = null;
  currentLocalizationStore: CurrentLocalizationStore = null;

  isMobile: boolean = false;
  isInitializing: boolean = true;
  isReserving: boolean = false;
  isComplete: boolean = false;
  isEnabled: boolean = true;
  isGdprRequired: boolean = false;
  isGdprConsentAgreed: boolean = false;
  masterServiceGroups: MasterServiceGroup[] = null;
  masterServiceItems: ObservableMap<string, MasterServiceItem> = null;
  users: User[] = null;
  maxStep: number = null;
  currentStep: number = null;
  minHeight: number = 250;
  companyName: string = null;
  companyEmail: string = null;
  companyWebsite: string = null;
  companyPhoneNumber: string = null;
  companyLogo: {[key: string]: any} = null;
  companySettings: { [key: string]: any } = null;
  companyLatitude: string = null;
  companyLongitude: string = null;
  estimate: Estimate = null;
  estimateTier: EstimateTier = null;

  privacyPolicy?: I18nString = null;
  termsOfService?: I18nString = null;

  /**
   * The initial options lets us configure the clientStore when it is invoked.
   * This lets us turn on/off certain settings while testing very easily.
   */
  constructor(options: IStoreOptions = { useTestData: false }) {
    this.currentLocalizationStore = new CurrentLocalizationStore();
    this.currentLocalizationStore.registerMessages(translationStrings);
    registerLocaleStoreInstance(this.currentLocalizationStore);

    initGraphql();

    this.clientId = clientId;
    this.locationStore = new LocationStore();
    this.pianoStore = new PianoStore();
    this.prefStore = new PrefStore();
    this.searchStore = new SearchStore();
    this.contactStore = new ContactStore();

    this.masterServiceItems = new ObservableMap<string, MasterServiceItem>();
    this.masterServiceGroups = [];
    this.users = [];
    this.setCurrentStep(1);

    void this.initializeData()
      .then(() => {
        this.locationStore.initializeData(this, options);
        this.pianoStore.initializeData(this, options);
        this.prefStore.initializeData(this);
        this.searchStore.initializeData(this, options);
        this.contactStore.initializeData(this, options);
      });

    makeObservable(this, {
      isMobile: observable,
      isInitializing: observable,
      isReserving: observable,
      isComplete: observable,
      isEnabled: observable,
      isGdprRequired: observable,
      isGdprConsentAgreed: observable,
      masterServiceGroups: observable,
      masterServiceItems: observable,
      users: observable,
      maxStep: observable,
      currentStep: observable,
      minHeight: observable,
      companyName: observable,
      companyEmail: observable,
      companyWebsite: observable,
      companyPhoneNumber: observable,
      companyLogo: observable,
      companySettings: observable,
      companyLatitude: observable,
      companyLongitude: observable,
      estimate: observable,
      estimateTier: observable,
      initializeData: action,
      createReservation: action,
      isStepUnlocked: observable,
      isStepComplete: computed,
      setCurrentStep: action,
      unlockStep: action,
      lockStep: action,
      setServices: action,
      setMinHeight: action,
      privacyPolicy: observable,
      termsOfService: observable,
    });
  }

  /**
   * A global utility to do a geocode lookup.  I found we were doing this in a lot of places,
   * and it made sense to centralize it for consistency.
   *
   * @param addressLine
   * @param options
   */
  async geocode(addressLine: string, options: {restrictType?: MAPPING_LOCATION_TYPE[] | MAPPING_LOCATION_TYPE} = {}): Promise<ICoordinates> {
    const data: any = await graphqlRequest(`
      mutation($addressLine: String!) {
        geocode(addressLine: $addressLine) {
          geocodedAddress { lat lng locationType }
        }
      }`, {addressLine: addressLine});

    let restrictType: MAPPING_LOCATION_TYPE[] = [];
    if (options.restrictType && !(options.restrictType instanceof Array)) {
      restrictType = [options.restrictType];
    }

    let pos: {lat: number, lng: number, mappingLocationType: MAPPING_LOCATION_TYPE} = {lat: null, lng: null, mappingLocationType: null};
    if (data.geocode && data.geocode.geocodedAddress) {
      const addr = data.geocode.geocodedAddress;
      if (restrictType && restrictType.length > 0) {
        if (restrictType.indexOf(data.geocode.geocodedAddress.locationType) >= 0) {
          pos = {lat: addr.lat, lng: addr.lng, mappingLocationType: addr.locationType};
        }
      } else {
        pos = {lat: addr.lat, lng: addr.lng, mappingLocationType: addr.locationType};
      }
    }
    return {
      latitude: pos.lat?.toString() || null,
      longitude: pos.lng?.toString() || null,
      mappingLocationType: pos.mappingLocationType
    };
  }

  /**
   * This uses promises to initially load data before the UI renders.
   * The UI is gated on the isInitializing boolean which is true until
   * the very end of this function.  Load any data you need globally
   * available here before the app renders.
   *
   * @returns {Promise<void>}
   */
  async initializeData() {
    let queries = [];
    actionLogger.debug('initializeData:begin');

    // -------------------------------------------------------------------
    // 1: Build up the queries and send one GraphQL request to the server.
    // -------------------------------------------------------------------
    let variables: {[key: string]: any} = {};
    queries.push(`
      company {
        name phoneNumber email website isGdprRequired lat lng countryCode
        logo {exists url}
        settings {
          phoneCountryCode
          selfSchedulerEnabled
          selfSchedulerShowCosts
          selfSchedulerWelcomeMessage
          selfSchedulerCompletionMessage
          selfSchedulerCompletionRedirectUrl
          selfSchedulerOutsideServiceAreaMessage
          selfSchedulerDefaultTechnician
          selfSchedulerAllowCoordinateLocationType
          relaxedAddressValidation
          typeaheadProvider
          interactiveMapProvider
          locationBias {lat lng radius}
          publicStyle {headerLayout}
          defaultCurrency { code symbol label divisor decimalDigits }
          smsEnabled isSmsOptIn
          publicPrivacyPolicy publicTermsOfService
        }
      }
    `);
    queries.push(`
      allAddressDisplayTemplates { countryCode singleLine multiLine summaryLine }
    `);
    queries.push(`
      globalConfig { gak azk mbpk }
    `);
    queries.push(`
      allMasterServiceGroups {
        id name isMultiChoice order
        allMasterServiceItems {
          id name description educationDescription duration amount type externalUrl order
          isDefault isTuning isTaxable isAnyUser
          allUsers { id firstName lastName }
        }
      }
    `);
    queries.push(`
      allUsers(status:ACTIVE, isSchedulable:true) {
        edges {
          node {
            id firstName lastName timezone
            settings {
              selfSchedulerShortTermLimitMessage selfSchedulerShortTermLimitPolicy selfSchedulerInternationalizedShortTermLimitMessage
            }
          }
        }
      }
    `);
    queries.push(COMPANY_LOCALIZATION_FIELDS);

    if (clientId) {
      variables['clientId'] = {value: clientId, type: 'String!'};
      queries.push(`
        client(id: $clientId) {
          preferredTechnicianId
          defaultContact {
            firstName lastName
            defaultEmail { email }
            defaultPhone { phoneNumber type }
            defaultLocation { street1 street2 municipality region postalCode countryCode usageType locationType latitude longitude }
            wantsText
          }
          allPianos(first:50, status:ACTIVE) {
            edges {
              node {
                id type make model location year
                calculatedLastService calculatedNextService
              }
            }
          }
        }
      `);
    }
    if (estimateId) {
      variables['estimateId'] = {value: estimateId, type: 'String!'};
      queries.push(`
        estimate(id: $estimateId) {
          ${ESTIMATE_ALL_GQL_QUERY_FIELDS}
        }
      `);
    }

    let paramStr = '';
    let paramVars: {[key: string]: any} = {};
    let varKeys = Object.keys(variables);
    if (varKeys.length > 0) {
      paramStr = `(${varKeys.map((key) => `$${key}: ${variables[key].type}`).join(', ')})`;
      varKeys.forEach((key) => {
        paramVars[key] = variables[key].value;
      });
    }

    // -------------------------------------------------------------------
    // 2: Execute the query and await the result
    // -------------------------------------------------------------------
    const data: any = await graphqlRequest(`query ${paramStr} { ${queries.join("\n")} }`, paramVars);

    // -------------------------------------------------------------------
    // 3: Populate the stores with data returned from the query
    // -------------------------------------------------------------------
    this.gak = data.globalConfig?.gak;
    this.azk = data.globalConfig?.azk;
    this.mbpk = data.globalConfig?.mbpk;

    this.currentLocalizationStore.registerLocalizations(data.allSupportedLocales, data.allLocalizations.map((loc: any) => new Localization(loc)));
    this.currentLocalizationStore.setLocale(getBrowserLanguageCode());
    registerGlobalCurrency(data.company.settings.defaultCurrency);
    setAddressDisplayTemplates(data.allAddressDisplayTemplates, data.company.countryCode);

    if (data.client) {
      const {defaultLocation} = data.client.defaultContact;

      if (defaultLocation) {
        if (getLocationTypeName(defaultLocation) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
          const addressLine = locationToSingleLine(defaultLocation);
          this.locationStore.locationType = LOCATION_TYPE.ADDRESS;

          if (defaultLocation.latitude && defaultLocation.longitude) {
            this.locationStore.addressLine = addressLine;
            this.locationStore.location = defaultLocation;
            this.locationStore.geocode = new Geocode({
              lat: parseFloat(defaultLocation.latitude),
              lng: parseFloat(defaultLocation.longitude),
            });
          } else {
            const {geocode}: any = await graphqlRequest(`
                mutation ($addressString: String!) {
                  geocode(addressLine: $addressString) {
                    geocodedAddress { lat lng }
                  }
                }`,
              {
                addressString: addressLine
              });

            // Only show their address if we are able to geocode.  Otherwise, prompt
            // them to search for their address again so we have an accurate address.
            if (addressLine && geocode.geocodedAddress && geocode.geocodedAddress.lat && geocode.geocodedAddress.lng) {
              this.locationStore.addressLine = addressLine;
              this.locationStore.location = defaultLocation;
              this.locationStore.geocode = new Geocode(geocode.geocodedAddress);
            }
          }
        } else if (getLocationTypeName(defaultLocation) === LOCATION_TYPE_NAME.COORDINATES) {
          this.locationStore.locationType = LOCATION_TYPE.COORDINATES;
          this.locationStore.addressLine = '';
          this.locationStore.location = defaultLocation;
          this.locationStore.geocode = new Geocode({lat: parseFloat(defaultLocation.latitude), lng: parseFloat(defaultLocation.longitude)});
        }
      }

      if (data.client.allPianos.edges.length > 0) {
        this.pianoStore.reset();
        data.client.allPianos.edges.forEach((edge: any) => {
          this.pianoStore.add(new Piano(edge.node));
        });
      }
    }

    // If we are coming from an estimate, pre-populate the piano and service information.
    if (data.estimate) {
      this.estimate = new Estimate(data.estimate);
      this.estimateTier = this.estimate.allEstimateTiers.find((tier: EstimateTier) => tier.id === estimateTierId);
      if (this.estimateTier) {
        let pianoWrapper: PianoWrapper = null;
        this.pianoStore.pianoWrappers.forEach(pw => {
          if (pw.piano?.id === this.estimate.piano.id) {
            pianoWrapper = pw;
          }
        });
        if (!pianoWrapper) {
          // need to create a new Piano model because estimate.piano is a shared Piano model,
          // but pianoStore requires a public Piano model.
          pianoWrapper = this.pianoStore.add(new Piano(data.estimate.piano));
        }
        this.estimateTier.allEstimateTierGroups.forEach((group) => {
          group.allEstimateTierItems.forEach((item) => {
            pianoWrapper.set(item);
          });
        });
        this.estimateTier.allUngroupedEstimateTierItems.forEach((item) => {
          pianoWrapper.set(item);
        });
      }
    }

    if (clientId) {
      this.isGdprConsentAgreed = true;
    }
    this.isGdprRequired = data.company.isGdprRequired;
    this.companyName = data.company.name;
    this.companyEmail = data.company.email;
    this.companyPhoneNumber = data.company.phoneNumber;
    this.companyWebsite = data.company.website;
    this.companyLogo = {
      exists: data.company.logo.exists,
      url: data.company.logo.url,
    };
    this.companySettings = {
      phoneCountryCode: data.company.settings.phoneCountryCode,
      headerLayout: data.company.settings.publicStyle.headerLayout,
      welcomeMessage: data.company.settings.selfSchedulerWelcomeMessage,
      completionMessage: data.company.settings.selfSchedulerCompletionMessage,
      completionRedirectUrl: data.company.settings.selfSchedulerCompletionRedirectUrl,
      outsideServiceAreaMessage: data.company.settings.selfSchedulerOutsideServiceAreaMessage,
      defaultTechnician: data.company.settings.selfSchedulerDefaultTechnician,
      showCosts: data.company.settings.selfSchedulerShowCosts,
      relaxedAddressValidation: data.company.settings.relaxedAddressValidation,
      mappingTypeaheadProvider: data.company.settings.typeaheadProvider,
      mappingInteractiveMapProvider: data.company.settings.interactiveMapProvider,
      locationBiasLat: data.company.settings.locationBias.lat,
      locationBiasLng: data.company.settings.locationBias.lng,
      locationBiasRadius: data.company.settings.locationBias.radius,
      smsEnabled: data.company.settings.smsEnabled,
      isSmsOptIn: data.company.settings.isSmsOptIn,
      allowCoordinateLocationType: data.company.settings.selfSchedulerAllowCoordinateLocationType,
    };
    if (this.locationStore.locationType !== LOCATION_TYPE.ADDRESS) {
      this.companySettings.allowCoordinateLocationType = true;
    }

    this.companyLatitude = data.company.lat;
    this.companyLongitude = data.company.lng;
    this.privacyPolicy = new I18nString(data.company.settings.publicPrivacyPolicy || {});
    this.termsOfService = new I18nString(data.company.settings.publicTermsOfService || {});

    this.isEnabled = data.company.settings.selfSchedulerEnabled;

    if (this.companySettings.mappingTypeaheadProvider === MAPPING_PROVIDER_TYPE.GOOGLE) {
      this.typeaheadProvider = new GoogleMappingTypeahead({
        countryCode: this.companySettings.phoneCountryCode,
        locationBiasLat: this.companySettings.locationBiasLat,
        locationBiasLng: this.companySettings.locationBiasLng,
        locationBiasRadius: this.companySettings.locationBiasRadius,
        autocompleteService: new google.maps.places.AutocompleteService(),
        PlacesServiceStatus: google.maps.places.PlacesServiceStatus,
        LatLng: google.maps.LatLng,
      });
    } else if (this.companySettings.mappingTypeaheadProvider === MAPPING_PROVIDER_TYPE.AZURE) {
      this.typeaheadProvider = new AzureMappingTypeahead({
        azk: this.azk,
        countryCode: this.companySettings.phoneCountryCode,
        locationBiasLat: this.companySettings.locationBiasLat,
        locationBiasLng: this.companySettings.locationBiasLng,
        locationBiasRadius: this.companySettings.locationBiasRadius,
        companyLat: data.company.lat,
        companyLng: data.company.lng,
      });
    // } else if (this.companySettings.mappingTypeaheadProvider === MAPPING_PROVIDER_TYPE.MAPBOX) {
    //   this.typeaheadProvider = new MapboxMappingTypeahead({
    //     mbpk: this.mbpk,
    //     countryCode: this.companySettings.phoneCountryCode,
    //     locationBiasLat: this.companySettings.locationBiasLat,
    //     locationBiasLng: this.companySettings.locationBiasLng,
    //     locationBiasRadius: this.companySettings.locationBiasRadius,
    //     companyLat: data.company.lat,
    //     companyLng: data.company.lng,
    //   });
    } else {
      throw `Unknown typeahead mapping provider: "${this.companySettings.mappingTypeaheadProvider}"`;
    }

    if (this.companySettings.mappingInteractiveMapProvider === MAPPING_PROVIDER_TYPE.GOOGLE) {
      const GoogleContainerClass = getGoogleInteractiveMapContainer();

      const ContainerByAddressClass = getGoogleInteractiveMapContainerByAddress({
        InteractiveMapContainer: GoogleContainerClass,
        LoadingIndicator: LoadingIndicator,
        geocode: async (addressLine: string): Promise<ICoordinates> => {
          return this.geocode(addressLine);
        }
      });

      const GoogleItineraryMapClass = getGoogleInteractiveItineraryMapContainer({
        defaultLat: data.company.lat,
        defaultLng: data.company.lng,
        polylineProvider: null
      });

      this.interactiveMapProvider = new GoogleMappingInteractiveMap({
        ContainerClass: GoogleContainerClass,
        ContainerByAddressClass: ContainerByAddressClass,
        ItineraryMapClass: GoogleItineraryMapClass,
      });
    /*
    } else if (this.companySettings.mappingInteractiveMapProvider === MAPPING_PROVIDER_TYPE.MAPBOX) {
      const MapboxContainerClass = getMapboxInteractiveMapContainer({
        mbpk: this.mbpk,
      });

      const MapboxContainerByAddressClass = getMapboxInteractiveMapContainerByAddress({
        InteractiveMapContainer: MapboxContainerClass,
        LoadingIndicator: LoadingIndicator,
        geocode: async (addressLine: string): Promise<{lat: number, lng: number}> => {
          return this.geocode(addressLine);
        }
      });

      const MapboxItineraryMapClass = getMapboxInteractiveItineraryMapContainer({
        mbpk: this.mbpk,
        defaultLat: data.company.lat,
        defaultLng: data.company.lng,
        polylineProvider: null,
      });

      this.interactiveMapProvider = new MapboxMappingInteractiveMap({
        ContainerClass: MapboxContainerClass,
        ContainerByAddressClass: MapboxContainerByAddressClass,
        ItineraryMapClass: MapboxItineraryMapClass,
      });
    */
    } else {
      throw `Unknown interactive mapping provider: "${this.companySettings.mappingInteractiveMapProvider}"`;
    }


    this.prefStore.setPreferredUser(null, true);
    data.allUsers.edges.forEach((edge: any) => {
      let user = new User(edge.node);
      this.users.push(user);
      if (data.client && data.client.preferredTechnicianId === user.id && this.companySettings.defaultTechnician === 'PREFERRED_TECHNICIAN') {
        // Also make sure that the preferred technician is listed as being able to perform at least one of
        // the master service items.
        const numServicesForPreferredUser = (
          flatten(data.allMasterServiceGroups.map((g: MasterServiceGroup) => g.allMasterServiceItems))
            .filter((i: MasterServiceItem) => {
              return (
                i.isAnyUser ||
                i.allUsers.filter(u => u.id === data.client.preferredTechnicianId).length > 0
              );
            })
            .length
        );
        if (numServicesForPreferredUser > 0) {
          this.prefStore.setPreferredUser(user, true);
        }
      }
      if (data.client && data.client.preferredTechnicianId) {
        this.prefStore.setClientPreferredTechnicianId(data.client.preferredTechnicianId);
      }
    });

    this.setServices(data.allMasterServiceGroups);

    this.contactStore.setContactInfo(this, data.client);

    // -------------------------------------------------------------------
    // 4: Say we're done so the UI can render
    // -------------------------------------------------------------------
    this.isInitializing = false;

    actionLogger.debug('initializeData:success');
  }

  async createReservation(): Promise<any> {
    actionLogger.debug('createReservation:begin');
    this.isReserving = true;


    let pianos: {[key: string]: any}[] = [];
    this.pianoStore.pianoWrappers.forEach(pianoWrapper => {
      if (pianoWrapper.numServices === 0) return;
      pianos.push({
        pianoId: pianoWrapper.piano.id,
        type: pianoWrapper.piano.type,
        make: pianoWrapper.piano.make,
        model: pianoWrapper.piano.model,
        location: pianoWrapper.piano.location,
        year: '' + pianoWrapper.piano.year,
        masterServiceItemIds: Array.from(pianoWrapper.services.values()),
        estimateTierItemIds: Array.from(pianoWrapper.estimateItems.values()).map(item => item.id),
      });
    });

    try {
      const {createSchedulerReservation: {mutationErrors, eventReservationId}}: any = await graphqlRequest(`
          mutation ($reservationParams: PublicCreateSchedulerReservationInput) {
            createSchedulerReservation(reservationParams: $reservationParams) {
              mutationErrors {key, messages}
              eventReservationId
            }
          }
        `,
        {
          reservationParams: {
            clientId: clientId,

            userId: this.searchStore.selectedSlot.user.id,
            startsAt: this.searchStore.selectedSlot.startsAt.toISOString(),
            timezone: this.searchStore.selectedSlot.timezone,
            audit: JSON.stringify(this.searchStore.selectedSlot.audit),

            notes: this.contactStore.notes,
            firstName: this.contactStore.contactInfo.firstName,
            lastName: this.contactStore.contactInfo.lastName,
            phoneNumber: this.contactStore.contactInfo.phoneNumber,
            phoneType: this.contactStore.contactInfo.phoneType,
            email: this.contactStore.contactInfo.email,

            addressLine: locationToSingleLine(this.locationStore.location),
            locationType: this.locationStore.locationType,
            ...(this.locationStore.locationType === LOCATION_TYPE.ADDRESS ? {
              street1: (this.locationStore.location as LocationAddressPartsType).street1,
              street2: (this.locationStore.location as LocationAddressPartsType).street2,
              municipality: (this.locationStore.location as LocationAddressPartsType).municipality,
              region: (this.locationStore.location as LocationAddressPartsType).region,
              postalCode: (this.locationStore.location as LocationAddressPartsType).postalCode,
              countryCode: (this.locationStore.location as LocationAddressPartsType).countryCode,
            } : {}),
            ...(this.locationStore.locationType === LOCATION_TYPE.COORDINATES ? {
              latitude: (this.locationStore.location as LocationCoordinatesType).latitude,
              longitude: (this.locationStore.location as LocationCoordinatesType).longitude,
            } : {}),

            isGdprConsentAgreed: this.isGdprConsentAgreed,
            estimateTierId: this.estimateTier ? this.estimateTier.id : null,
            // Send a null value (meaning "question wasn't asked") if the company does not have SMS enabled.
            smsOptIn: this.companySettings.smsEnabled ? this.contactStore.contactInfo.smsOptIn : null,

            pianos: pianos,

            schedulerV2SearchId: this.searchStore.searchId,
            travelMode: this.searchStore.selectedSlot.travelMode,
            availabilityId: this.searchStore.selectedSlot.availabilityId,
          }
        });

      this.isReserving = false;
      if (eventReservationId) {
        actionLogger.debug('createReservation:success');
        return null;
      } else {
        actionLogger.debug('createReservation:mutationErrors', mutationErrors);
        return mutationErrors;
      }
    } catch (e) {
      actionLogger.debug('createReservation:error', e.message);
      this.isReserving = false;
      return [{key: null, messages: [e.message]}];
    }
  }


  isStepUnlocked: {[key: number]: boolean} = {
    1: true
  };
  get isStepComplete(): {[key: number]: boolean} {
    return {
      1: this.locationStore.verifiedAddress,
      2: this.pianoStore.numServices > 0 && this.pianoStore.totalDuration > 0,
      3: !!this.prefStore.targetDate && this.prefStore.computedUserIds.length > 0,
      4: !!this.searchStore.selectedSlot,
      5: true,
      6: true,
    };
  }

  setCurrentStep(step: number): boolean {
    if (this.isStepUnlocked[step]) {
      let data: any = {
        location: this.locationStore.location,
        geocode: this.locationStore.geocode,
      };
      if (this.pianoStore && this.pianoStore.totalDuration && step > 2) {
        data.duration = this.pianoStore.totalDuration;
      }
      if (this.prefStore && this.prefStore.targetDate && step > 3) {
        data.prefs = {
          targetDate: this.prefStore.targetDate.format(),
          isAnyUserSelected: this.prefStore.isAnyUserSelected,
          selectedUsers: this.prefStore.selectedUsers.map(u => u && u.id)
        };
      }
      if (this.searchStore.selectedSlot) {
        data.selectedSlot = {
          startsAt: moment.tz(this.searchStore.selectedSlot.startsAt, this.searchStore.selectedSlot.timezone).format(),
          timezone: this.searchStore.selectedSlot.timezone,
          duration: this.pianoStore.totalDuration,
          userName: this.searchStore.selectedSlot.user.fullName,
          userId: this.searchStore.selectedSlot.user.id,
        };
      }
      if (this.contactStore.contactInfo.firstName) data.contactInfo = this.contactStore.contactInfo;
      actionLogger.debug('setCurrentStep:' + step, data);

      this.currentStep = step;
      return true;
    } else {
      return false;
    }
  }

  unlockStep(step: number) {
    this.isStepUnlocked[step] = true;
  }

  lockStep(step: number) {
    for (let i = step; i <= 6; i++) {
      this.isStepUnlocked[i] = false;
    }
  }

  setServices(masterServiceGroups: any) {
    masterServiceGroups.forEach((group: any) => {
      let msGroup = new MasterServiceGroup(group);
      this.masterServiceGroups.push(msGroup);
      group.allMasterServiceItems.forEach((item: any) => {
        let msItem = new MasterServiceItem(item);
        msItem.masterServiceGroup = msGroup;
        this.masterServiceItems.set(msItem.id, msItem);
      });
    });
  }

  setMinHeight(val: number) {
    this.minHeight = val;
  }


}

let rootStoreInstance: RootStore = null;

function initializeRootStore() {
  rootStoreInstance = new RootStore({useTestData: urlContainsDebug()});
}

export { rootStoreInstance, RootStore, IStoreOptions, initializeRootStore };
