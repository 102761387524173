import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { PianoWrapper } from '../../../models/piano_wrapper';
import { MasterServiceList } from './MasterServiceList';
import { PianoTypeInput } from "./PianoTypeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { formatCurrency, formatDate, formatDuration, formatMessage } from "@gazelle/shared/utils";
import { EstimateTierList } from "@client_scheduler/modules/step2_pianos/components/EstimateTierList";
import {
  MSG_aboutYourPianoTitle,
  MSG_chooseServicesTitle,
  MSG_costWithValueLabel,
  MSG_durationWithValueLabel,
  MSG_estimateNumberAndExpirationTitle,
  MSG_locationExamplePlaceholder,
  MSG_locationLabel,
  MSG_makeExamplePlaceholder,
  MSG_makeLabel,
  MSG_modelExamplePlaceholder,
  MSG_modelLabel,
  MSG_optionalTitle,
  MSG_removeAllServicesTitle,
  MSG_yearExamplePlaceholder,
  MSG_yearLabel,
  MSG_yearValidationError
} from "@gazelle/shared/strings";
import { rootStoreInstance } from '@client_scheduler/modules/root';

interface IPianoFormProps {
  pianoWrapper: PianoWrapper,
  footer?: React.Component | React.ReactElement,
  onClear: (pianoWrapper: PianoWrapper) => void,
}

const PianoForm = observer(class PianoForm extends React.Component<IPianoFormProps> {
  messages: any;

  constructor(props: IPianoFormProps) {
    super(props);
  }

  handleClear = () => {
    this.props.onClear(this.props.pianoWrapper);
    // If they're resetting the services, we should also reset the selected technicians
    // since different services may require different technicians.
    rootStoreInstance.prefStore.resetSelectedUsers();
  };


  render() {
    const { pianoWrapper } = this.props;
    const showCosts = rootStoreInstance.companySettings.showCosts;

    let localization = undefined;
    if (rootStoreInstance.estimate) {
      localization = rootStoreInstance.currentLocalizationStore.getLocalizationFor(rootStoreInstance.estimate.locale);
    }

    if (!pianoWrapper) return <div/>;

    return (
      <div className="piano-form">
        <h4 className="first-header">{formatMessage(MSG_aboutYourPianoTitle)}</h4>

        <PianoTypeInput type={pianoWrapper.piano.type}
                        onChange={type => pianoWrapper.piano.type = type}/>

        <Row>
          <Col xs={12} lg={5}>
            <FormGroup>
              <Label>
                {formatMessage(MSG_makeLabel)}
                <span className="ms-3 text-muted text-uppercase text-small">{formatMessage(MSG_optionalTitle)}</span>
              </Label>
              <Input
                autoFocus
                name="make"
                value={pianoWrapper.piano.make}
                onChange={(e) => pianoWrapper.piano.make = e.target.value}
                placeholder={formatMessage(MSG_makeExamplePlaceholder)} />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <FormGroup>
              <Label>
                {formatMessage(MSG_modelLabel)}
                <span className="ms-3 text-muted text-uppercase text-small">{formatMessage(MSG_optionalTitle)}</span>
              </Label>
              <Input
                name="model"
                value={pianoWrapper.piano.model}
                onChange={(e) => pianoWrapper.piano.model = e.target.value}
                placeholder={formatMessage(MSG_modelExamplePlaceholder)} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7}>
            <FormGroup>
              <Label>
                {formatMessage(MSG_locationLabel)}
                <span className="ms-3 text-muted text-uppercase text-small">{formatMessage(MSG_optionalTitle)}</span>
              </Label>
              <Input
                name="location"
                value={pianoWrapper.piano.location}
                onChange={(e) => pianoWrapper.piano.location = e.target.value}
                placeholder={formatMessage(MSG_locationExamplePlaceholder)} />
            </FormGroup>
          </Col>
          <Col xs={12} md={5}>
            <FormGroup>
              <Label>
                {formatMessage(MSG_yearLabel)}
                <span className="ms-3 text-muted text-uppercase text-small">{formatMessage(MSG_optionalTitle)}</span>
              </Label>
              <Input
                name="year"
                value={pianoWrapper.piano.year}
                onChange={(e) => pianoWrapper.piano.year = e.target.value}
                placeholder={formatMessage(MSG_yearExamplePlaceholder)} />

              {pianoWrapper?.piano?.year.length > 0 && pianoWrapper?.piano?.year.length !== 4 && (
                <div className="text-color-alert-red small-font-size">
                  {formatMessage(MSG_yearValidationError)}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {pianoWrapper.estimateItems.size > 0 ? (
              <Col>
                <h4 className="mt-5">
                  {formatMessage(MSG_estimateNumberAndExpirationTitle, {
                    estimateNumber: rootStoreInstance.estimate.number,
                    date: formatDate(rootStoreInstance.estimate.expiresOn)
                  })}
                </h4>
                <div className="pb-4">
                  <EstimateTierList tier={rootStoreInstance.estimateTier} pianoWrapper={pianoWrapper} localization={localization}/>
                </div>
              </Col>
            ) : (
              <Col>
                <h4 className="mt-5">{formatMessage(MSG_chooseServicesTitle)}</h4>
                <Button className="float-right" color="link" size="sm" onClick={this.handleClear}>
                  {formatMessage(MSG_removeAllServicesTitle)}
                </Button>
                <div className="pb-4">
                  <MasterServiceList pianoWrapper={pianoWrapper} />
                </div>
              </Col>
            )}
        </Row>

        <Row className="overflowing-footer">
          <Col xs={12} md={this.props.footer ? 6 : 12}>
            <div className="duration-container">
              <div className="left-side d-md-none d-lg-block">
                <FontAwesomeIcon icon={faClock} />
              </div>
              <div className="right-side">
                {formatMessage(MSG_durationWithValueLabel, {duration: formatDuration(pianoWrapper.totalDuration)})}
                {showCosts &&
                  <div>{formatMessage(MSG_costWithValueLabel, {cost: formatCurrency(pianoWrapper.totalAmount)})}</div>}
              </div>
            </div>
          </Col>
          {this.props.footer &&
            <Col xs={12} md={6} className="text-end footer-column">
              <div className="d-md-none"><br/></div>
              {this.props.footer}
            </Col>}
        </Row>
      </div>
    );
  }
});

export { PianoForm };
