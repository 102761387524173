import gql from "graphql-tag";
import {
  PrivateEventCoreInfoFragment,
  PrivateEventRecurrenceInfoFragment,
  PrivateEventRemoteCalendarInfoFragment
} from "./event";
import { PrivateClientDefaultContactNameFragment } from "./client";
import { PrivatePianoInfoFragment } from "./piano";
import { PrivateSchedulerAvailabilityFragment } from "./scheduler_v2";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivateCalendarSummaryInRangeV2Query = gql`
  query ($userId: [String!]!, $singleUserId: String, $startOn: CoreDate!, $endOn: CoreDate!){
    allEvents(userId: $userId, startOn: $startOn, endOn: $endOn){
      id type status title start duration buffer timezone isAllDay confirmedAt confirmationWarning
      recurrenceId lifecycleState remoteType
      user { id color firstName lastName }
      allEventPianos {
        nodes { isTuning piano { id status type make model serialNumber year location }}
      }
      client {
        id companyName
        defaultContact {
          id firstName lastName isDefault
          defaultLocation { ...PrivateContactLocationFullInfoFragment }
        }
      }
      remoteCalendar { id calendarDisplayName type remoteCalendarIntegration {id} impactsAvailability}
    }

    allEventReservations(userId: $userId, startOn: $startOn, endOn: $endOn, status: PENDING) {
      id startsAt timezone duration buffer status
      clientData {
        firstName lastName street1 street2 municipality region postalCode countryCode latitude longitude geocodeType locationType
      }
      user { id color firstName lastName }
    }

    allSchedulerAvailabilities(userId: $singleUserId, startOn: $startOn, endOn: $endOn) {
      ...PrivateSchedulerAvailabilityFragment
    }
  }

  ${PrivateSchedulerAvailabilityFragment}
  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateItineraryV2Query = gql`
  query ($userId: [String!]!, $singleUserId: String, $date: CoreDate!){
    allEvents(userId: $userId, startOn: $date, endOn: $date) {
      ...PrivateEventCoreInfoFragment
      ...PrivateEventRecurrenceInfoFragment
      ...PrivateEventRemoteCalendarInfoFragment
      client {
        id companyName
        lifecycle { id name confWindowCode }
        ...PrivateClientDefaultContactNameFragment
        defaultContact {
          id
          defaultLocation { ...PrivateContactLocationFullInfoFragment }
          defaultEmail {
            id email
          }
          defaultPhone {
            id phoneNumber extension type confirmedClass
            e164: phoneNumber(format: E164)
          }
        }
      }
      allEventPianos {
        nodes {
          isTuning
          piano { ...PrivatePianoInfoFragment }
        }
      }
      schedulerAvailability {
        id
        startOfDayLocation { manualLat manualLng }
        endOfDayLocation { manualLat manualLng }
      }
    }

    allEventReservations(userId: $userId, startOn: $date, endOn: $date, status: PENDING) {
      id startsAt timezone duration buffer status travelMode
      clientData {
        firstName lastName street1 street2 municipality region postalCode countryCode latitude longitude geocodeType locationType
      }
      user { id color firstName lastName }
      schedulerAvailability {
        id
        startOfDayLocation { manualLat manualLng }
        endOfDayLocation { manualLat manualLng }
      }
    }

    allSchedulerAvailabilities(userId: $singleUserId, startOn: $date, endOn: $date) {
      ...PrivateSchedulerAvailabilityFragment
      serviceArea { id travelMode }
    }
  }

  ${PrivateEventCoreInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
  ${PrivateClientDefaultContactNameFragment}
  ${PrivatePianoInfoFragment}
  ${PrivateEventRemoteCalendarInfoFragment}
  ${PrivateSchedulerAvailabilityFragment}
  ${PrivateContactLocationFullInfoFragment}
`;
